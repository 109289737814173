import React, { PureComponent } from "react";
import classNames from "classnames";
import DOMPurify from 'dompurify';

DOMPurify.addHook('afterSanitizeAttributes', function (node) {
  const anchor = document.createElement('a');
  if (node.hasAttribute('href')) {
    anchor.href = node.getAttribute('href');
    if (anchor.hostname && !anchor.hostname.endsWith("turgoil.com")) {
      node.removeAttribute('href');
    }
  }
});

type Props = {
  datetime: string;
  position?: string;
  content: string;
};

class Message extends PureComponent<Props> {
  static defaultProps = {
    position: "left",
  };

  render() {
    const { datetime, content, position } = this.props;

    return (
      <div
        className={classNames("message", {
          "message--right": position === "right",
          "message--left": position === "left",
        })}
      >
        <div
          className="message__content"
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content, { ALLOWED_TAGS: ['br', 'a'] }) }}
        />
        <small className="message__date">{datetime}</small>
      </div>
    );
  }
}

export default Message;

import { jwtDecode } from "jwt-decode";
import {
  createBrowserRouter,
  LoaderFunctionArgs,
  redirect,
  RouteObject,
} from "react-router-dom";
import AccountRecoveryPage from "./components/AccountRecoveryPage";
import AdminPage from "./components/AdminPage";
import App from "./components/App";
import ConversationPage from "./components/ConversationPage";
import CreateOilRequestPage from "./components/CreateOilRequestPage";
import IndexPage from "./components/IndexPage";
import InformationPage from "./components/InformationPage";
import OilRequestPage from "./components/OilRequestPage";
import OilRequestsPage from "./components/OilRequestsPage";
import SettingsPage from "./components/SettingsPage";
import SignInPage from "./components/SignInPage";
import SignUpPage from "./components/SignUpPage";
import { getAuthenticationToken } from "./utils/user";

function protectedLoader({ request }: LoaderFunctionArgs) {
  // TODO check JWT
  const fakeAuthProvider = {
    isAuthenticated: getAuthenticationToken() !== null,
  };
  // If the user is not logged in and tries to access `/protected`, we redirect
  // them to `/login` with a `from` parameter that allows login to redirect back
  // to this page upon successful authentication
  if (!fakeAuthProvider.isAuthenticated) {
    const url = new URL(request.url);
    return redirect("/signin?redirectTo=" + encodeURIComponent(url.pathname));
  }

  return null;
}

function protectedLoaderForAdmin({ request }: LoaderFunctionArgs) {
  // TODO check JWT
  const fakeAuthProvider = {
    isAuthenticated: getAuthenticationToken() !== null,
  };
  // If the user is not logged in and tries to access `/protected`, we redirect
  // them to `/login` with a `from` parameter that allows login to redirect back
  // to this page upon successful authentication
  if (!fakeAuthProvider.isAuthenticated) {
    const url = new URL(request.url);
    return redirect("/signin?redirectTo=" + encodeURIComponent(url.pathname));
  }

  const decodedToken = jwtDecode<{ id: string, exp: number, role: string }>(getAuthenticationToken());
  if (decodedToken.role !== 'Admin') {
    return redirect("/oil-requests");
  }

  return null;
}

const routes: RouteObject[] = [
  {
    path: "/",
    Component: App,
    children: [
      {
        path: "/",
        Component: IndexPage,
      },
      {
        path: "/advertisement",
        element: (
          <InformationPage>
            <InformationPage.Advertisement />
          </InformationPage>
        ),
      },
      {
        path: "/frequently-asked-questions",
        element: (
          <InformationPage>
            <InformationPage.FrequentlyAskedQuestions />
          </InformationPage>
        ),
      },
      {
        path: "/instructions",
        element: (
          <InformationPage>
            <InformationPage.Instructions />
          </InformationPage>
        ),
      },
      {
        path: "/account-recovery",
        Component: AccountRecoveryPage,
      },
      {
        path: "/signin",
        Component: SignInPage,
      },
      {
        path: "/signup",
        Component: SignUpPage,
      },
      {
        path: "/terms-and-conditions",
        element: (
          <InformationPage>
            <InformationPage.TermsAndConditions />
          </InformationPage>
        ),
      },
      {
        path: "/privacy-policy",
        element: (
          <InformationPage>
            <InformationPage.PrivacyPolicy />
          </InformationPage>
        ),
      },
      {
        path: "/settings",
        Component: SettingsPage,
        loader: protectedLoader,
      },
      {
        path: "/oil-requests",
        Component: OilRequestsPage,
        loader: protectedLoader,
      },
      {
        path: "/oil-requests/create",
        Component: CreateOilRequestPage,
        loader: protectedLoader,
      },
      {
        path: "/oil-requests/:id",
        Component: OilRequestPage,
        loader: protectedLoader,
      },
      {
        path: "/conversations",
        Component: ConversationPage,
        loader: protectedLoader,
      },
      {
        path: "/conversations/:id",
        Component: ConversationPage,
        loader: protectedLoader,
      },
      {
        path: "/admin",
        Component: AdminPage,
        loader: protectedLoaderForAdmin,
      },
    ],
  },
];

export default createBrowserRouter(routes);
